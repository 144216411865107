<template>
  <div id="app">
    <nav>
      <div class="nav-content">
        <img src="images/logo2.png" alt="Le Caprice Logo" class="logo">
        <div class="burger-menu" @click="toggleMenu">
          <i class="fas fa-bars"></i>
        </div>
        <ul :class="{ 'menu-open': menuOpen }">
          <li><router-link to="/" exact @click="closeMenu"><i class="fas fa-home"></i> Accueil</router-link></li>
          <li><router-link to="/menu" @click="closeMenu"><i class="fas fa-utensils"></i> Menu</router-link></li>
          <li><router-link to="/reservations" @click="closeMenu"><i class="fas fa-calendar-alt"></i> Réservations</router-link></li>
          <li><router-link to="/contact" @click="closeMenu"><i class="fas fa-envelope"></i> Contact</router-link></li>
          <li><router-link to="/about" @click="closeMenu"><i class="fas fa-info-circle"></i> À propos</router-link></li>
        </ul>
      </div>
    </nav>
    <div class="content">
      <router-view/> <!-- Vue Router va rendre le composant correspondant à la route actuelle ici -->
    </div>
    <CookieConsent /> <!-- Bandeau de consentement aux cookies -->
    <footer class="footer">
        <div class="footer-info">
          <h3>Caprice Wavre</h3>
          <p>Avenue de la Gare 1 à 1300 Limal, Belgique</p>
          <p>+32 (0) 483 62 36 34</p>
          <p>info@capricewavre.be</p>
          <div class="button-container">
      <button class="nav-button btn-sm" @click="openGoogleMaps">
        <i class="fas fa-map-marker-alt"></i> Google Maps
      </button>
      <button class="nav-button btn-sm" @click="openWaze">
        <i class="fab fa-waze"></i> Waze
      </button>
    </div>
      </div>
      <div class="footer-social">
          <h4>Suivez-nous</h4>
          <div class="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61557539921629" target="_blank"><i class="fab fa-facebook-f"></i></a>
            <a href="https://www.instagram.com/capricewavre/" target="_blank"><i class="fab fa-instagram"></i></a>
          </div>
        </div>
      <div class="footer-copyright">
        <p>&copy; 2024 Caprice. Tous droits réservés.</p>
        <p>Made with <span class="heart">&hearts;</span> by <a href="https://cyberglow.be" target="_blank">CyberGlow</a></p>
      </div>
      <div class="footer-links">
        <router-link :to="{ name: 'PrivacyPolicyPage' }">Politique de Confidentialité</router-link>
        <router-link :to="{ name: 'LegalNoticePage' }">Mentions Légales</router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import CookieConsent from './components/CookieConsent.vue';

export default {
  name: 'App',
  components: {
    CookieConsent,
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    openGoogleMaps() {
      const lat = 50.69128165206855;
      const lng = 4.574716906395293;
      const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
      window.open(url, '_blank');
    },
    openWaze() {
      const lat = 50.69128165206855;
      const lng = 4.574716906395293;
      const url = `https://waze.com/ul?ll=${lat},${lng}&navigate=yes`;
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>
@import './assets/custom-styles.css';

/* Footer Styles */
footer {
  background-color: #1a1a1a;
  color: var(--text-color);
  padding: 20px 0;
  margin-top: auto;
}
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
.footer-info
{
  text-align: center;
}
.footer-info h3,
.footer-social h4 {
  margin-bottom: 10px;
  font-size: 3rem;
  color: #b87333;
}

.social-icons a {
  font-size: 2.5rem;
  color: #e0e0e0;
}

.footer-social {
  text-align: center;
  font-size: 0.9rem;
}

.footer-links {
  text-align: center;
  margin-top: 15px;
}

.footer-links a {
  color: var(--text-color);
  margin: 0 10px;
  text-decoration: none;
}

.footer-links a:hover {
  color: var(--primary-color);
}
.footer-copyright {
  text-align: center;
  padding-top: 10px;
  border-top: 1px solid #333;
  margin-top: 20px;
  color: var(--text-color);
}

.footer-copyright p {
  margin: 5px 0;
  font-size: 0.9rem;
}

.footer-copyright .heart {
  color: red;
  font-size: 1.2rem;
  vertical-align: middle;
}

.footer-copyright a {
  color: var(--primary-color);
  text-decoration: none;
}

.footer-copyright a:hover {
  color: var(--secondary-color);
}

.nav-button {
  background-color: #b87333;
  color: white;
  border: none;
  padding: 8px 18px;
  margin: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
  width: 150px; /* Set a fixed width for both buttons */
  justify-content: center; /* Center the text and icon within the button */
}

.nav-button i {
  font-size: 0.9rem; /* Same size for both icons */
  vertical-align: middle;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 30px 20px;
  }
  .nav-button {
    width: 100%; /* Make buttons full-width on small screens */
    justify-content: center;
  }
}

</style>
